interface ChatInfo {
  is_system: string;
  name: string;
  id: number;
}
enum ChatRoleType {
  toolHelper = "工具小助手",
  producerHelper = "制片小助手",
  other = "其他",
}

const getChatRoleType = (val: ChatInfo, isId: boolean): ChatRoleType => {
  if (val.is_system === "yes" && val.name === "工具小助手") {
    return ChatRoleType.toolHelper;
  } else if (val.is_system === "yes" && val.name === "制片小助手") {
    return ChatRoleType.producerHelper;
  } else if (isId && val.id === 2) {
    return ChatRoleType.producerHelper;
  } else if (isId && val.id === 252) {
    return ChatRoleType.toolHelper;
  } else {
    return ChatRoleType.other;
  }
};

export { getChatRoleType, ChatRoleType, ChatInfo };
